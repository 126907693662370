import React from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
// import Home from './components/Home';
import Upload from './components/Upload';
import Crop from './components/crop';
import HowToPlay from './components/howtoplay';
import NewHome from './components/newhome';

export default function App() {
  return (
    <div>
      <Router>
            <Routes>
            {/* centralize div */}
						{/* <Route path="/login"  element={<SignIn/>} /> */}
						<Route path="/" element={<NewHome/>} />
						<Route path="/upload" element={<Upload/>} />
						<Route path="/howtoplay" element={<HowToPlay/>} />
						<Route path="/newhome" element={<NewHome/>} />
						<Route path="/crop" element={<Crop/>} />
            {/* Catch-all route for all other routes */}
            <Route path="*" element={<Navigate to="/" />} />
            </Routes>
				</Router>
    </div>
  );
}