import React, { useState, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './utils/cropImage';

const CropImage = () => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const overlayRef = useRef(null);

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImage(croppedImage);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setCroppedImage(null);
      setShowModal(true);
    };
    reader.readAsDataURL(file);
  };

  const onUploadClick = () => {
    setShowModal(false);
  };

  const onDownloadClick = () => {
    const link = document.createElement('a');
    link.download = 'cropped-image.png';
    link.href = overlayRef.current.toDataURL();
    link.click();
  };

  return (
    <div>
      <input type="file" onChange={onFileChange} />
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {image && (
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
          {croppedImage && (
            <div style={{ position: 'relative' }}>
              <img src={croppedImage} alt="Cropped" />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                ref={overlayRef}
              >
                Great job!
              </div>
            </div>
          )}
          {croppedImage && (
            <button onClick={onUploadClick}>Upload</button>
          )}
        </div>
      )}
      {croppedImage && (
        <button onClick={onDownloadClick}>Download</button>
      )}
    </div>
  );
};

export default CropImage;
