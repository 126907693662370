import React, { useState } from "react";
import html2canvas from "html2canvas";
import Cropper from "react-easy-crop";
import ImageUploading from "react-images-uploading";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import primeLogo from "../../assets/images/prime_logo@2x.png";
import primewhiteLogo from "../../assets/images/primelogowhite@2x.png";
import golLogo from "../../assets/images/gangsoflagosfinal09-1@2x.png";
import instructionimg from "../../assets/images/instructions@2x.png";
import iconCheck from "../../assets/img/Icons/Icon-check.svg";
import iconCorrect from "../../assets/img/Icons/Icon-correct.svg";
import iconWrong from "../../assets/img/Icons/Icon-wrong.svg";
import Badwords from "../elements/badwords.json";
import UploadControl from "../elements/UploadControl";
import { cropImage } from "./canvasUtils";
import Resizer from "react-image-file-resizer";
import Loader from "../elements/Loader";
import closeImage from "../../assets/images/close.png";

// const url = "https://gangsoflogos.com"; // PRODUCTION

const ImageUploadingButton = ({ value, onChange, isEditMode, ...props }) => {
  const [loading, setLoading] = useState(false);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2400,
        2400,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onImageUpload = async (imageList) => {
    setLoading(true);
    const imageListResized = await Promise.all(imageList.map((image) => resizeFile(image.file)));
    const imageListResizedBase64 = imageListResized.map((image) => ({ dataURL: image }));
    onChange(imageListResizedBase64);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <ImageUploading value={value} onChange={onImageUpload}>
      {({ onImageUpload, onImageUpdate }) => (
        <div>
          <div className={`${!isEditMode ? 'instruction-box' : ''}`}>
              {!isEditMode && (
                <img src={instructionimg} width="343" height="142" />
              )} 
            <p>
              <a onClick={value ? onImageUpload : () => onImageUpdate(0)}
                {...props}
                //className="btn fw-bold bg-black btn-upload-picture"
                className={`btn ${isEditMode ? 'btn-re-upload-picture' : 'btn-upload-picture'}`}
                >
                  {!isEditMode ? 'Upload Picture' : 'Edit Image'}
                </a>
            </p>
          </div>
        </div>
      )}
    </ImageUploading>
  );
};

const ImageCropper = ({
  open,
  image,
  onComplete,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth >
      <DialogTitle
        style={{
          textAlign: "center",
          color: "#000",
          fontSize: "22px",
          fontWeight: "bold",
          fontFamily: "Sharp Grotesk",
          textTransform: "uppercase",
          backgroundColor: "#ffd60a", borderRadius: 0
        }}
      >Pan and crop photo</DialogTitle>

      <DialogContent style={{ backgroundColor: "#ffd60a" }}>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>

        <div className="d-flex justify-content-center">

          <button onClick={() =>
            onComplete(cropImage(image, croppedAreaPixels, console.log))
          } className="btn btn-lg btn-dark fw-bold bg-black play-now btn_300 mt-5">Crop image</button>
        </div>
      </DialogContent>


    </Dialog>
  );
};

export default function App() {
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    name: '',
    error: false,
    checked: false,
    errorText: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const handleCropImage = async (image) => {
    setLoading(true)
    setCroppedImage(image);
    setDialogOpen(false);
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  };

  const handleOnChange = (e) => {
    e.persist();
    if (e.target.value.match(/\d/)) {
      setInputs((prev) => ({
        ...prev,
        error: true,
        errorText: 'Name cannot contain numbers',
      }));
      return;
    }
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
      error: false,
      checked: false,
      errorText: '',
    }));
  };

  let closeImg = {cursor:'pointer', float:'right', marginTop: '1px', width: '30px'};
  function ImageModal({ imageUrl, onClose }) {
    return (
      <Dialog 
      open={imageUrl !== null}
      maxWidth="sm" fullWidth >
      <DialogTitle
        style={{
          textAlign: "center",
          color: "#000",
          fontSize: "22px",
          fontWeight: "bold",
          fontFamily: "Sharp Grotesk",
          textTransform: "uppercase",
          backgroundColor: "#ffd60a", borderRadius: 0
        }}
      >
        <img src={closeImage} style={closeImg} onClick={onClose}/>
      </DialogTitle>

      <DialogContent style={{ backgroundColor: "#ffd60a" }}>
          <img src={imageUrl} alt="Generated Image" width="100%" />
          <p style={{
          textAlign: "center",
          color: "#000",
          fontSize: "22px",
          fontWeight: "bold",
          fontFamily: "Sharp Grotesk",
          textTransform: "uppercase",
          backgroundColor: "#ffd60a", borderRadius: 0,
          lineHeight: 1, 
          marginTop: 10
        }}>Tap and hold to save to gallery</p>
          
       </DialogContent>
       </Dialog>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleDownloadClick = () => {
    setIsLoading(true);
    const capture = document.getElementById("capture");
    html2canvas(capture, {
      allowTaint: true,
      useCORS: true,
      width: capture.scrollWidth,
      height: capture.scrollHeight
    })
    .then(canvas => {
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          resolve(blob);
        }, 'image/jpeg', 1);
      });
    })
    .then(async blob => {
      const dataUrl = await blobToDataURL(blob);
      setImageUrl(dataUrl);
      console.log(dataUrl);
    })
    .catch(error => {
      console.error(error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  /*
  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const capture = document.getElementById("capture");
      const canvas = await html2canvas(capture, {
        allowTaint: true,
        useCORS: true,
        width: capture.scrollWidth,
        height: capture.scrollHeight
      });
      canvas.toBlob(function(blob) {
        //const url = URL.createObjectURL(blob);
        const dataUrl = await blobToDataURL(blob); // convert blob URL to data URL
        
        setImageUrl(url);
        console.log(url);
        //const link = document.createElement('a');
        //link.download = 'image.jpg';
        //link.href = url;
        //link.click();
      }, 'image/jpeg', 1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return error;
    }
  };
  */
  const handleCloseModal = () => {
    setImageUrl(null);
  };

  const checkName = () => {
    if (inputs.name === '') {
      return;
    }

    if (inputs.name.length > 15) {
      setInputs((prev) => ({
        ...prev,
        error: true,
        checked: true,
        errorText: 'Name must be less than 16 characters !',
      }));
      return;
    }


    const name = inputs.name.toLowerCase();
    if (Badwords.includes(name)) {
      setInputs((prev) => ({
        ...prev,
        error: true,
        checked: true,
        errorText: 'No swear words allowed!',
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        error: false,
        checked: true,
      }));
    }

    const inputField = document.querySelector('.location-input');
    const inputValue = inputField ? inputField.value : null;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button_image_click',
      inputFieldValue: inputValue
    });
  }

  const checkImageUpload = async () => {

    const imageUrl = localStorage.getItem("imageUrl");
    console.log('imageUrlfromlocal', imageUrl)
    if (imageUrl === null) {
      console.log('cool')
      const image = await handleDownloadClick();
      return image;
    } else {
      console.log('okay')
      return imageUrl;
    }
  }


  
  const shareFacebook = async () => {
    const imageUrl = await checkImageUpload();
    const url = `https://www.facebook.com/sharer.php?u=${imageUrl}`;
    window.location = url;
  }

  const shareTwitter = async () => {
    const imageUrl = await checkImageUpload();
    const text = 'Sharing my love for the gang. Rep your hood by creating your own on gangsoflagosonprime.ng, then share on social #GangsOfLagosOnPrime.'
    const url = ` https://twitter.com/intent/tweet/?text=${text}&url=${imageUrl}`;
    window.location = url;
  }

  const shareWhatsapp = async () => {
    const imageUrl = await checkImageUpload();
    console.log('imageUrl', imageUrl)
    const text = `Sharing my love for the gang. Rep your hood by creating your own on gangsoflagosonprime.ng, then share on social #GangsOfLagosOnPrime.`;
    const url = `whatsapp://send?text=${text}`;
    window.location = url;
  }


  const shareInstagram = async () => {
    // show a text alert infomring the user they cant share directly in instagram but they show use the download button to download the image and share it in instagram from their gallery
    const text = 'You can only share this badge on Instagram after downloading it. Click the download button to download the badge and share it in Instagram from your gallery.'
    alert(text)
  }

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <main>
      <header>
        <div className="pt-4 pb-2 text-center">
          <a href="/"><img width="116" height="66px" className="mb-3" src={primeLogo} alt="" /></a>
          <a href="/"><img className="d-block mx-auto mb-3 p-0" src={golLogo} alt="" width="329px" height="60px" /></a>
        </div>

        <ImageCropper
          open={dialogOpen}
          image={image.length > 0 && image[0].dataURL}
          onComplete={(imagePromisse) => {
            imagePromisse.then((image) => {
              handleCropImage(image);
            });
          }}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: 300,
            background: "#333"
          }}
        />

        {!croppedImage && (
          <div className="row px-3">
            <div className="col-md-10 mx-auto">
              <div className="header-description-section mb-4">
                <div className="decsription-text">
                  Enter your location into the field below to have it on your photo
                  filter
                </div>
              </div>

              <div className="section-head pt-md-3">
                <h2>GANGS OF ...</h2>
              </div>

              <div className=" mb-3">
                <div className="row g-0">
                  <div className="col">
                    <input
                      type="text"
                      //className="form-control location-input" 
                      className={`form-control location-input ${inputs.error ? 'is-invalid' : ''}`}
                      placeholder="Enter location..."
                      aria-label="Enter location..."
                      aria-describedby="button-addon2"
                      id="name"
                      name="name"
                      onChange={handleOnChange}
                      required
                    />

                    <button type="submit" className="btn-check-location p-0 m-0" onClick={checkName}>

                      {!inputs.checked ? <img src={iconCheck} onClick={checkName} alt="" /> : null}
                      {inputs.checked &&
                        <img className="btn-select-location" src={inputs.error ? iconWrong : iconCorrect} onClick={checkName} alt="" />
                      }
                    </button>


                  </div>
                </div>

                {inputs.error &&
                  <div className="invalid-feedback d-block">
                    {inputs.errorText}
                  </div>
                }

              </div>

              {/* <UploadControl disabled={!(inputs.name && inputs.checked && !inputs.error)} /> */}

              {inputs.checked &&
                !inputs.error ?

                <ImageUploadingButton
                  value={image}
                  onChange={(newImage) => {
                    setDialogOpen(true);
                    setImage(newImage);
                  }}
                  isEditMode={false}
                />
                : null
              }

              <div className="text-center my-5"> </div>

            </div>
          </div>
        )}

        {croppedImage && (<>
          <div className="row px-4">
            <div className="col-11 mx-auto" >
              <div

                className="square"
                id='capture'
                style={{
                  backgroundImage: `url(${croppedImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div className="square_content">

                  <div className="gangtext text-white">
                    <p>Gangs</p>
                  </div>
                  <div className="bg-black location d-flex">
                    <div className="location-of text-white">
                      <p>Of</p>
                    </div>
                    <div className="location-text">
                      <h2 className="pb-0 gb-0">
                        {inputs.name}
                      </h2>
                    </div>

                  </div>
                  <div className="hash-text">
                    <span className="text-white"> #GangsOfLagosOnPrime</span>
                  </div>

                </div>
              </div>
            </div>
          </div>


          {/* <div className="social-links mt-3">
            <button onClick={() => shareFacebook()}>
              <i className="bx bxl-facebook"></i>
            </button>
            <button  onClick={() => shareTwitter()}>
              <i className="bx bxl-twitter"></i>
            </button>

            <button  onClick={() => shareWhatsapp()}>
              <i className="bx bxl-whatsapp"></i>
            </button>

            <button onClick={() => shareInstagram()}>
              <i className="bx bxl-instagram"></i>
            </button>
          </div> */}

          <div className="col-11 mx-auto text-center">

          <ImageUploadingButton
              value={image}
              onChange={(newImage) => {
                setDialogOpen(true);
                setImage(newImage);
              }}
             // set isEditMode to true to show the edit button
              isEditMode={true}
            />

            <p className="py-3 download-bottom">
              <button className="download-image" onClick={handleDownloadClick}>Download image</button>
            </p>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                <div className="spinner-border spinner-border-sm spinner text-white" style={{ width: '30px', height: '30px' }} role="status">

                </div>
                <div style={{ color: '#fff', fontWeight: 'bold', marginLeft: 5 }}>
                  Generating image...
                </div>
              </div>
            )}

          {imageUrl && (
                  <ImageModal imageUrl={imageUrl} onClose={handleCloseModal} />
                )}

            <p>
              <a href="https://www.primevideo.com/" className="btn fw-bold w-100 btn-prime">
                Watch Now on &nbsp; <img width="61" height="38" className="" src={primewhiteLogo} alt="" />
              </a>
            </p>

            <p >
              <a href="/upload" className="btn fw-bold w-100 bg-black btn-upload-picture">RESTART</a>
            </p>
          </div>
        </>
        )}
      </header>
    </main>
  );
}



