import React from "react";
import primeLogo from "../../assets/images/prime_logo@2x.png";
import gangsOfLagosLogo from "../../assets/images/gangsoflagosfinal09-1@2x.png";

const HowToPlay = ({rootHeight}) => {
  return (
    <div style={{ height: rootHeight }} className="min-h-screen bg-cover bg-center bg-fixed bg-no-repeat bg-opacity-50 how-to-page">
      <div className="mx-auto pt-4 px-3 max-w-xl">
        <div><a href="/"><img width="116" height="66px" className="mb-3" src={primeLogo} alt="" /></a> </div>
        <img
          src={gangsOfLagosLogo}
          alt="Gangs of Lagos Logo"
          className="my-3"
          width="329px" 
          height="60px" 
        />
        <h3 className="">How to Create Your Filter</h3>
        <div className="text-xl how-to-box">
          <ol>
              <li>Enter the hood you represent, don't give your exact location.</li>
              <li>Upload your photo (Group or solo). Do not use any sexual, illegal or offensive images. Be cool!</li>
              <li>Download and share online.</li>
          </ol>
        </div>
        <a href="/upload" className="btn btn-lg btn-dark fw-bold bg-black play-now btn_300">
          GET STARTED
        </a>
      </div>
    </div>
  );
};

export default HowToPlay;
