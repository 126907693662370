import React from "react";
import primeLogo from "../../assets/images/prime_logo@2x.png";
import gangsOfLagosTitle from "../../assets/images/gangsoflagostitle.png";

const NewHome = ({rootHeight}) => {
  return (
    <div style={{ height: rootHeight }} className="min-h-screen bg-cover bg-center bg-fixed bg-no-repeat bg-opacity-50 home-page">
      <div className="mx-auto pt-4 px-3 max-w-xl">
        <div><a href="/"><img width="116" height="66px" className="mb-3" src={primeLogo} alt="" /></a> </div>
        <img
          src={gangsOfLagosTitle}
          alt="Gangs of Lagos Logo"
          className="my-3"
          width="330px" 
          height="247px" 
        />
        <div><a href="https://www.primevideo.com/detail/0RJC5H8ZXGS8ZAZLXFE0R4YJTB/ref=atv_hm_hom_c_7xW3NA_awns_1_2?jic=16%7CCgNhbGwSA2FsbA%3D%3D" className="btn fw-bold how-to-play btn_300 my-4">WATCH NOW</a></div>
        <div>
          <a href="/upload" className="btn btn-lg btn-dark fw-bold bg-black play-now btn_300 mb-4">CREATE NOW</a>
        </div>
        <div><a href="/howtoplay" className="btn fw-bold how-to-play btn_300">HOW TO CREATE</a></div>
      </div>
    </div>
  );
};

export default NewHome;
